.ant-typography-edit {
    margin-left: 10px;
    display: flex;
    align-items: center;
    font-size: 14px;
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    background-color: #f5f5f5;
}

::-webkit-scrollbar {
    width: 8px;
    background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: rgb(85, 85, 85, 0.3);
}

.ql-tooltip.ql-editing {
    left: 0px !important;
}
